import Empresa from "@/components/globalconfig/components/apps/Empresa"
import Lance from "@/components/globalconfig/components/apps/Lance"
import Clientes from "@/components/globalconfig/components/apps/Clientes"
import Interface from "@/components/globalconfig/components/apps/Interface"
import Redefinir from "@/components/globalconfig/components/apps/Redefinir"
import Comunicacao from "@/components/globalconfig/components/apps/Comunicacao"
import Robo from "@/components/globalconfig/components/apps/Robo"
import Caller from "@/components/globalconfig/components/apps/Caller"
import Apps from "@/components/globalconfig/components/apps/Apps"
import Comitente from "@/components/globalconfig/components/apps/Comitente"
import Processos from "@/components/globalconfig/components/apps/Processos"
import Tarefas from "@/components/globalconfig/components/apps/Tarefas"
import Marketing from "@/components/globalconfig/components/apps/Marketing"
import Financeiro from "@/components/globalconfig/components/apps/Financeiro"
import Bem from "@/components/globalconfig/components/apps/Bem"
import IntegraImovelWeb from "@/components/globalconfig/components/apps/IntegraImovelWeb"
import IntegraResale from "@/components/globalconfig/components/apps/IntegraResale"
import IntegraSantanderImoveis from "components/globalconfig/components/apps/IntegraSantanderImoveis";
import IntegraRobosProcessos from "components/globalconfig/components/apps/IntegraRobosProcessos";

export default {
    empresa: {
        name: 'empresa',
        component: Empresa,
        label: 'Empresa'
    },
    bem: {
        name: 'bem',
        component: Bem,
        label: 'Bem'
    },
    lance: {
        name: 'lance',
        component: Lance,
        label: 'Lance'
    },
    arrematante: {
        name: 'clientes',
        component: Clientes,
        label: 'Clientes'
    },
    interface: {
        name: 'interface',
        component: Interface,
        label: 'Interface'
    },
    redefinir: {
        name: 'redefinir',
        component: Redefinir,
        label: 'Redefinir'
    },
    comunicacao: {
        name: 'comunicacao',
        component: Comunicacao,
        label: 'Comunicação'
    },
    financeiro: {
        name: 'financeiro',
        component: Financeiro,
        label: 'Comunicação'
    },
    robo: {
        name: 'robo',
        component: Robo,
        label: 'Robô'
    },
    caller: {
        name: 'caller',
        component: Caller,
        label: 'Caller'
    },
    apps: {
        name: 'apps',
        component: Apps,
        label: 'Apps Móveis'
    },
    comitente: {
        name: 'integra-comitentes',
        component: Comitente,
        label: 'Comitente'
    },
    processos: {
        name: 'processos',
        component: Processos,
        label: 'Processos'
    },
    tarefas: {
        name: 'tarefas',
        component: Tarefas,
        label: 'Tarefas'
    },
    projetos: {
        name: 'projetos',
        component: () => import('@/components/globalconfig/components/apps/Projetos'),
        label: 'Projetos'
    },
    marketing: {
        name: 'marketing',
        component: Marketing,
        label: 'Marketing'
    },
    imovelweb: {
        name: 'integra-imovelweb',
        component: IntegraImovelWeb,
        label: 'Imóvel Web'
    },
    resale: {
        name: 'integra-resale',
        component: IntegraResale,
        label: 'Imóvel Web'
    },
    santanderImoveis: {
        name: 'integra-santander-imoveis',
        component: IntegraSantanderImoveis,
        label: 'Santander Imóveis'
    },
    roboProcessos: {
        name: 'integra-robo-processos',
        component: IntegraRobosProcessos,
        label: 'Robô Processos'
    }
}
