<template>
  <div class="config-app">
    <h2 class="app-title">Financeiro</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo financeiro.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper label="Emitir nota de arrematação somente após o recebimento do pagamento do lote">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.notaSomenteAposPagto').value" />
          <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, somente será possível gerar a Nota de Arrematação após o recebimento do pagamento total devido pelo arremate.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Categorias Expandidas">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.categoriasExpandidas').value" />
          <p class="app-legend m-t-xs m-b-none">Se sim, oculta as categorias filhas e somente mostra após expansão pelo usuário.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Lançar vendas dos Lotes de Leilões no Financeiro">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.lancarVendaLoteFinanceiro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Obrigar anexar comprovante registrar como pago um lançamento">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('financeiro.forcarAnexoAoPagar').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Categoria para destino da receita">
          <categoria-hirerarquia-select v-model="c('financeiro.categoriaLancamento').value" :tipo="1" :search-only-select="false" />
          <p class="app-legend m-t-xs m-b-none">Esta categoria será para o lançamneto principal, detalhes do lançamento, como impostos e taxas, serão lançados de acordo à escolha no cadastro do <a href="/#/configuracoes/taxas">Sistema de Taxas</a> para o leilão.</p>
        </app-label-wrapper>
      </div>
      <list-formas-pagamento v-if="active === 'formas-pagamento'" class="app-input-container" />
    </app-tab>
    <app-label-wrapper>
      <div class="m-t">
        <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
      </div>
    </app-label-wrapper>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import ListFormasPagamento from "@/components/cadastro/components/formaspagamento/List"
import {USelect} from "uloc-vue"
import CategoriaHirerarquiaSelect from "components/financeiro/components/input/CategoriaHirerarquiaSelect"

export default {
  name: "ConfigFinanceiro",
  components: {
    CategoriaHirerarquiaSelect,
    ListFormasPagamento,
    USelect
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'formas-pagamento',
          label: 'Formas de Pagamento'
        }
      ]
    }
  }
}
</script>
